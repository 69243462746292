<template>
  <!-- banner -->
  <AppBanner :url="require('@/assets/image/cover/news.png')" title="最新消息" />
  <AppContentNav v-model="activeId" :items="navs" />
  <!-- 新聞公告 -->
  <section v-for="l in infoAllNews" :key="l.id">
    <div class="container py-6" v-if="arrayLimitLength(l.data, 2).length">
      <h2 class="content-title fs-1 text-center mb-5 mb-lg-8">{{ l.name }}</h2>
      <div class="row mb-lg-5 g-lg-8" v-if="l.id === TYPE_NEWS.id && firstNews">
        <div class="col-12 col-lg-6">
          <NewsCard :item="firstNews" :showImage="true" imgPos="header" />
        </div>
        <div class="col-12 col-lg-6" v-if="news">
          <div class="row">
            <div class="col-6 mb-3" v-for="newItem in news" :key="newItem.articleUuid">
              <NewsCard :item="newItem" />
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-lg-5 g-lg-8" v-else>
        <div class="col-6 mb-3" v-for="data in arrayLimitLength(l.data, 2)" :key="data.articleUuid">
          <NewsCard :item="data" />
        </div>
      </div>
    </div>

    <div class="text-center" v-if="arrayLimitLength(l.data, 2).length">
      <button
        class="btn btn-secondary text-white rounded-0 fs-5"
        @click="$router.push(`/news/${l.id}`)"
      >
        查看更多
      </button>
    </div>
  </section>
</template>

<script>
import { apiQueryUserArticle } from "@/api/article";
import NewsCard from "@/components/NewsCard.vue";
import { ARTICLE_TYPES, TYPE_NEWS, NEWS_INFO } from "@/constants/article";

export default {
  components: {
    NewsCard,
  },
  data() {
    return {
      activeId: "NEWS",
      navs: [
        { id: 1, name: "新聞消息", path: "/news/1" },
        { id: 2, name: "公告", path: "/news/2" },
        { id: 3, name: "活動", path: "/news/3" },
      ],
      TYPE_NEWS,
      articleTypes: ARTICLE_TYPES,
      articles: [],
      pages: {
        totalPages: 1,
        currentPage: 0,
      },
    };
  },
  computed: {
    news() {
      const maxLen = 3;
      const content = this.articles.filter((article) => article.categoryId === TYPE_NEWS.id) || [];
      return content.slice(1, maxLen);
    },
    firstNews() {
      const [first] = this.articles.filter((article) => article.categoryId === TYPE_NEWS.id);
      return first
        ? { ...first, imagePath: require("@/assets/image/cover/news.png") } || null
        : null;
    },
    infoAllNews() {
      return this.articleTypes.reduce((acc, current) => {
        const data = this.articles.filter((i) => i.categoryId === current.id);
        return [...acc, { ...current, data }];
      }, []);
    },
    arrayLimitLength() {
      return (arr, limit) => {
        return arr && arr.slice(0, limit);
      };
    },
  },
  methods: {
    async queryArticleList(page = 1) {
      this.$vLoading(true);
      try {
        const res = await apiQueryUserArticle({ page, type: NEWS_INFO.key });
        const { code, data, message } = res.data;
        if (code === 200) {
          const { content, pageInfo } = data;
          const { totalPages, currentPage } = pageInfo;
          this.articles = content.map((c) => ({
            ...c,
            path: `/news/${c.type.toLowerCase()}/${c.articleUuid}`,
          }));
          this.pages = {
            totalPages,
            currentPage,
          };
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
      }
    },
  },
  created() {
    this.queryArticleList();
  },
};
</script>
